<template>
  <main
    class="w-full h-screen flex items-center justify-center bg-gray-100"
  >
    <section class="w-full max-w-xl flex flex-col">
      <img
        src="@/assets/images/under-construction.svg"
        alt="ilustrasi halaman sedang dalam pengembangan"
        width="363px"
        height="229px"
        class="max-w-full object-cover object-center mb-8 mx-auto"
      >
      <h1 class="font-lora text-[21px] leading-[34px] text-green-700 font-bold mb-4 text-center">
        Oops, Portal Jabar CMS sedang dalam tahap pemeliharaan
      </h1>
      <p class="font-lato text-md font-medium text-blue-gray-800 text-center leading-6 mb-8">
        Kami mohon maaf atas ketidaknyamanan yang mungkin timbul.
        Pemeliharaan ini merupakan bagian dari upaya kami untuk meningkatkan kualitas layanan dan integritas keamanan sistem.
        Untuk melihat informasi dan berita terbaru mengenai Jawa Barat, silakan kunjungi
        <a
          class="underline text-blue-500"
          href="https://jabarprov.go.id/"
        >
          jabarprov.go.id
        </a>
      </p>
      <p class="font-lato text-md font-normal text-gray-800 text-center leading-6">
        Untuk pertanyaan atau klarifikasi lebih lanjut, silakan menghubungi tim kami melalui email di
        <a
          href="mailto:opsteam@digitalservice.id"
          class="underline text-blue-500"
        >
          opsteam@digitalservice.id
        </a>
      </p>
    </section>
  </main>
</template>

<script>
export default {

};
</script>
